.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.fullscreen_btn{
  text-align: center;
}

.fullscreen_btn button {
  font-size: 20px;
  background-color: #31b062;
  color: #fff;
  border: 0;
  box-shadow: none;
  padding: 15px 30px;
  position: absolute;
  z-index: 10;
  margin: 50px 30px auto;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 5px;
  width: max-content;
}

.main {
  position: relative;
}

.popup {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.7);
}

.popup_box {
  width: 100%;
  height: 100%;
  background: #fff;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  z-index: 11;
}

.popup_close {
  background-color: #31b062;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  display: inline-block;
  padding: 5px 10px 10px;
  float: right;
  cursor: pointer
}

.popup_content {
  text-align: center;
}

@media only screen and (max-width: 1080px) {
  .large_screen {
    display: none;
  }
}

@media only screen and (min-width: 1081px) {
  .small_screen {
    display: none;
  }
}
